<template>
  <div class="card">
    <BlockUI :blocked="showLoading">
      <Card>
        <template #content>
          <div class="p-fluid formgrid grid">
            <div class="field col-5 md:col-5">
              <label for="lotacao">Lotação</label>
              <InputText
                id="lotacao"
                v-model="contrachequeValido.lotacao"
                disabled />
            </div>
            <div class="field col-5 md:col-5">
              <label for="unidadeDeTrabalho">Unidade de Trabalho</label>
              <InputText
                id="unidadeDeTrabalho"
                v-model="contrachequeValido.unidadeDeTrabalho"
                disabled />
            </div>
            <div class="field col-2 md:col-2">
              <label for="periodo">Periodo</label>
              <InputText
                id="periodo"
                v-model="contrachequeValido.periodo"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-5 md:col-5">
              <label for="servidor">Servidor</label>
              <InputText
                id="servidor"
                v-model="contrachequeValido.servidor"
                disabled />
            </div>
            <div class="field col-3 md:col-3">
              <label for="matricula">Matricula</label>
              <InputText
                id="matricula"
                v-model="contrachequeValido.matricula"
                disabled />
            </div>
            <div class="field col-4 md:col-4">
              <label for="cpf">CPF</label>
              <InputText id="cpf" v-model="contrachequeValido.cpf" disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-5 md:col-5">
              <label for="cargo">Cargo</label>
              <InputText
                id="cargo"
                v-model="contrachequeValido.cargo"
                disabled />
            </div>
            <div class="field col-3 md:col-3">
              <label for="unidadePagadora">Unidade Pagadora</label>
              <InputText
                id="unidadePagadora"
                v-model="contrachequeValido.unidadePagadora"
                disabled />
            </div>
            <div class="field col-4 md:col-4">
              <label for="regime">Regime</label>
              <InputText
                id="regime"
                v-model="contrachequeValido.regime"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col md:col">
              <label for="tempoEstado">T.S. Estado</label>
              <InputText
                id="tempoEstado"
                v-model="contrachequeValido.tempoEstado"
                disabled />
            </div>
            <div class="field col md:col">
              <label for="tempoQuinquenio">T.S. Quinquênio</label>
              <InputText
                id="tempoQuinquenio"
                v-model="contrachequeValido.tempoQuinquenio"
                disabled />
            </div>
            <div class="field col md:col">
              <label for="tempoAposentadoria">T.S. Aposentadoria</label>
              <InputText
                id="tempoAposentadoria"
                v-model="contrachequeValido.tempoAposentadoria"
                disabled />
            </div>
            <div class="field col md:col">
              <label for="tempoAdquirido">T.S. Adquirido</label>
              <InputText
                id="tempoAdquirido"
                v-model="contrachequeValido.tempoAdquirido"
                disabled />
            </div>
            <div class="field col md:col">
              <label for="classeFuncional">CLF</label>
              <InputText
                id="classeFuncional"
                v-model="contrachequeValido.classeFuncional"
                disabled />
            </div>
            <div class="field col-5 md:cl-5">
              <label for="codigoCertificador">Código Certificador</label>
              <InputText
                id="codigoCertificador"
                v-model="contrachequeValido.codigoCertificador"
                disabled />
            </div>
          </div>

          <div class="p-fluid formgrid grid">
            <div class="field col md:col">
              <label for="bruto">Bruto R$</label>
              <InputText
                id="bruto"
                v-model="contrachequeValido.bruto"
                disabled />
            </div>
            <div class="field col md:col">
              <label for="liquido">Líquido R$</label>
              <InputText
                id="liquido"
                v-model="contrachequeValido.liquido"
                disabled />
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
  </div>
</template>
<script>
import ContrachequeService from '@/service/ContrachequeService'
export default {
  props: ['codigo', 'cpf', 'bruto'],

  data() {
    return {
      showLoading: false,
      params: new Map(),
      contrachequeValido: {},
    }
  },

  created() {
    this.contrachequeService = new ContrachequeService(this.$http)
    this.showLoading = true
  },

  mounted() {
    this.carregarContracheque()
  },

  methods: {
    carregarContracheque() {
      this.params.set('codigo', this.codigo)
      this.params.set('cpf', this.cpf)
      this.params.set('bruto', this.bruto)

      this.contrachequeService
        .validarContracheque(this.params)
        .then((res) => {
          this.contrachequeValido = res
          this.showLoading = false
          this.tratarBrutoEDescontos()
        })
        .catch((err) => {
          this.showToastError(err)
        })
    },

    tratarBrutoEDescontos() {
      this.contrachequeValido.bruto =
        parseFloat(this.contrachequeValido.bruto) / 100
      this.contrachequeValido.descontos =
        parseFloat(this.contrachequeValido.descontos) / 100

      this.contrachequeValido.liquido =
        this.contrachequeValido.bruto - this.contrachequeValido.descontos
    },

    showToastError(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>
<style scoped>
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10%;
}
</style>
